var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "box" },
    [
      _c("Header", {
        staticClass: "fixed-title",
        attrs: { title: "产品申购协议", back: true },
      }),
      _vm._m(0),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "doc_news" }, [
      _c("div", { staticClass: "second" }, [
        _vm._v("NJS INTERNATIONAL SUBSCRIPTION AGREEMENT"),
      ]),
      _c("p", { staticClass: "sub" }, [
        _vm._v("PLEASE READ CAREFULLY BEFORE SIGNING"),
      ]),
      _c("p", [
        _vm._v(
          "This Subscription Agreement (the “Agreement”) is made and entered into by and between the undersigned (the “Investor”) and NJS International, a Cayman Islands exempted company (the “Company”), with reference to the facts set forth below. Terms used, but not otherwise defined herein, shall have the meanings given to them in the Note."
        ),
      ]),
      _c("p", [
        _vm._v(
          "Whereas, subject to the terms and conditions of this Agreement, the Terms of Service, and the Privacy Policy, the Investor wishes to, from time to time, subscribe for and purchase certain Notes made available to Investor by the Company through the Platform, as may be amended from time to time."
        ),
      ]),
      _c("p", [
        _vm._v(
          "Whereas, each Note will be a non-recourse obligation of the Company and payment on such Note will be dependent on the payments that the Company receives from the Underlying Loan."
        ),
      ]),
      _c("p", [
        _vm._v(
          "Now therefore, in order to implement the foregoing and in consideration of the mutual representations, warranties, covenants and agreements contained herein and by setting forth their signatures below, on the signature date listed below, the parties hereto agree as follows:"
        ),
      ]),
      _c("h3", [_vm._v("1.Overview")]),
      _c("p", [
        _vm._v(
          "The Notes are being issued to provide funding for the origination of the Underlying Loan, the payments from which will be used to make payments due under the terms of the Notes. The Notes are unsecured obligations of the Company and are non-recourse to the Company’s other assets. The notes are only recourse to the extent that the Company receives Underlying Loan Payments, as more particularly described in the Note."
        ),
      ]),
      _c("h3", [_vm._v("2.Subscription for the Purchase of Notes")]),
      _c("p", [
        _vm._v(
          "A. Subject to the express terms and conditions of this Agreement and the Notes, the Investor hereby subscribes for Notes in an amount shown below the Investor’s signature below. The purchase price of such Notes will be set forth on the Note at the time of issuance. The Investor understands and agrees that this Agreement is intended to be binding on the Company."
        ),
      ]),
      _c("p", [
        _vm._v(
          "B. Each offering of a series of Notes is available to the Investor through http://www.fundusd.com or other website and mobile applications associated (Platform). Please read this Agreement carefully. While these documents are subject to change, as described below, the Company advises the Investor to print and retain a copy of these documents."
        ),
      ]),
      _c("p", [
        _vm._v(
          "C. The Company has the right to reject this Subscription for any reason. The Investor may not cancel, terminate or revoke this Agreement, which shall be binding upon the Investor, the Investor’s heirs, trustees, beneficiaries, executors, personal or legal administrators or representatives, successors, transferees and assigns, as applicable."
        ),
      ]),
      _c("p", [
        _vm._v(
          "D. Prior to issuance, the Company may, for any reason (or no reason), in its sole discretion, cancel or remove an offering of a series of Notes from the Platform and cancel, without liability, all investor purchase commitments relating to the corresponding series of Notes."
        ),
      ]),
      _c("p", [
        _vm._v(
          "E. If this Subscription is accepted by the Company, the Investor agrees to comply fully with the terms of this Agreement, the Note and all other applicable documents or instruments of the Company. The Investor further agrees to execute any other necessary documents or instruments in connection with this Subscription and the Investor’s purchase of the Notes."
        ),
      ]),
      _c("h3", [_vm._v("3.Purchase of Notes")]),
      _c("p", [
        _vm._v(
          "A. The Investor understands that the principal amount of the Note is payable with the execution and submission of the Note."
        ),
      ]),
      _c("p", [
        _vm._v(
          "B. Once an Investor makes a funding commitment to purchase a Note, it is irrevocable until the Note is issued or the purchase is rejected by the Company."
        ),
      ]),
      _c("p", [
        _vm._v(
          "C. The Investor understands that the execution and submission of a Note shall constitute an explicit authorization for the Company to conduct transactions related to the Note, including (i) withdrawals from Investor’s designated bank account in an amount totaling the purchase price of the Note, (ii) movement of such funds into the possession of the Company upon the issuance of the Note, (iii) return of such funds to Investor’s designated bank account if the Company is unable or unwilling to authorize the issuance of the Note, and (iv) any and all transactions as may be necessary for the Company to make payments to Investor under the terms of the Note."
        ),
      ]),
      _c("p", [
        _vm._v(
          "D. In the event that the purchase of a Note is rejected or the offering is terminated, the Company shall refund to the Investor any payment made by the Investor to the Company with respect to the rejected Note without interest and without deduction, and all of the obligations of Investor hereunder shall remain in full force and effect except for those obligations with respect to the rejected Note, which shall terminate."
        ),
      ]),
      _c("h3", [_vm._v("4.Terms of the Notes")]),
      _c("p", [
        _vm._v(
          "A. In the event that there is any conflict of interpretation of terms among parties to the Offering Materials, the Note shall control."
        ),
      ]),
      _c("p", [
        _vm._v(
          "B. Investor has no right to, and shall not, make any attempt, directly or through any third party, to take collection action with respect to the Underlying Loan or any Underlying Loan Payment."
        ),
      ]),
      _c("p", [
        _vm._v(
          "C. The Notes shall have the terms and conditions described in the Offering Materials, which will be available to Investor for review on the Platform."
        ),
      ]),
      _c("p", [
        _vm._v(
          "D. Each Note will be a non-recourse obligation of the Company."
        ),
      ]),
      _c("p", [
        _vm._v(
          "E. Payment by the Company on Notes will be dependent on the receipt of Underlying Loan Payments by the Company. In the event that payments on the Underlying Loan are not received, no payment on the Note will be due and payable."
        ),
      ]),
      _c("p", [
        _vm._v(
          "F. Each Note will be the obligation of the Company upon the receipt of Underlying Loan Payments."
        ),
      ]),
      _c("h3", [_vm._v("5.General Investor Representations")]),
      _c("p", [
        _vm._v(
          "The Investor represents and warrants to the Company the following, and the Investor will solely bear the loss or cost or expenses incurred due to any error or mistake in making the following representation and warranties:"
        ),
      ]),
      _c("p", [
        _vm._v(
          "A. The information that the Investor has furnished herein and on the Platform, is correct and complete as of the date of this Agreement and will be correct and complete on the date, if any, that the Company accepts this subscription. Further, the Investor shall immediately notify the Company of any change in any statement made herein prior to the Investor’s receipt of the Company’s acceptance of this Subscription. The representations and warranties made by the Investor may be fully relied upon by the Company and by any investigating party relying on them."
        ),
      ]),
      _c("p", [
        _vm._v(
          "B. The information that the Investor has furnished or will furnish in connection with the purchase of a Note will be correct and complete as of the date, if any, that the Company issues the Note. Further, the Investor shall immediately notify the Company of any change in any statement made in connection with the purchase of a Note prior to the Investor’s receipt of any issued Note."
        ),
      ]),
      _c("p", [
        _vm._v(
          "C. The Investor has the requisite power and authority to deliver this Agreement, perform his or her obligations set forth herein, and consummate the transactions contemplated hereby. The Investor has duly executed and delivered this Agreement and has obtained the necessary authorization to execute and deliver this Agreement and to perform his or her obligations herein and to consummate the transactions contemplated hereby. This Agreement, assuming the due execution and delivery hereof by the Company, is a legal, valid and binding obligation of the Investor enforceable against the Investor in accordance with its terms."
        ),
      ]),
      _c("p", [
        _vm._v(
          "D. At no time has it been expressly or implicitly represented, guaranteed or warranted to the Investor by the Company or any other person that:"
        ),
      ]),
      _c("p", [
        _vm._v(
          "i. A percentage of profit and/or amount or type of gain or other consideration will be realized as a result of this investment;or"
        ),
      ]),
      _c("p", [
        _vm._v(
          "ii. The past performance or experience on the part of the Company and/or its officers or directors in any way indicates the predictable or probable results of the ownership of the Notes."
        ),
      ]),
      _c("p", [
        _vm._v(
          "E. The Investor’s true and correct full legal name, address of residence, phone number, electronic mail address and other contact information are accurately provided to the Company through the Platform. The Investor is currently a bona fide resident of the state or jurisdiction set forth in the home state provided to the Company."
        ),
      ]),
      _c("p", [
        _vm._v(
          "F. The Investor is subscribing for the purchase of Notes solely for the Investor’s own account, for investment purposes only, and not with a view towards or in connection with resale, distribution (other than to its shareholders or members, if any), subdivision or fractionalization thereof. The Investor has no agreement or other arrangement, formal or informal, with any person or entity to sell, transfer or pledge any part of the Notes, or which would guarantee the Investor any profit, or insure against any loss with respect to the Notes, and the Investor has no plans to enter into any such agreement or arrangement."
        ),
      ]),
      _c("p", [
        _vm._v(
          "G. The Investor represents and warrants that the execution and delivery of this Agreement, the consummation of the transactions contemplated thereby and hereby and the performance of the obligations thereunder and hereunder will not conflict with or result in any violation of or default under any provision of any other agreement or instrument to which the Investor is a party or any license, permit, franchise, judgment, order, writ or decree, or any statute, rule or regulation, applicable to the Investor. The Investor confirms that the consummation of the transactions envisioned herein, including, but not limited to, the Investor’s purchase, will not violate any foreign law and that such transactions are lawful in the Investor’s country of citizenship and residence."
        ),
      ]),
      _c("h3", [
        _vm._v("6.Investor Representations Regarding Investment Terms"),
      ]),
      _c("p", [
        _vm._v(
          "The Investor represents and warrants to the Company the following:"
        ),
      ]),
      _c("p", [
        _vm._v(
          "A. The Investor has received, carefully read and is familiar with the terms and provisions of this Agreement, the Notes that it intends to purchase."
        ),
      ]),
      _c("p", [
        _vm._v(
          "B. INVESTOR UNDERSTANDS AND ACKNOWLEDGES THAT THE BORROWER MAY DEFAULT ON THE UNDERLYING LOAN AND THAT SUCH DEFAULT WILL REDUCE THE AMOUNTS THAT INVESTORS MAY RECEIVE UNDER THE TERMS OF ANY RELATED NOTES. INVESTOR FURTHER ACKNOWLEDGES THAT THE COMPANY’S ENFORCEMENT OF ITS RIGHTS AND REMEDIES WITH RESPECT TO THE UNDERLYING LOAN DURING ANY DEFAULT MIGHT NOT RESULT IN THE COMPANY RECOVERING THE FULL AMOUNT OF THE UNDERLYING LOAN PAYMENTS."
        ),
      ]),
      _c("p", [
        _vm._v(
          "C. The Investor has received all information that it considers necessary or appropriate for deciding whether to purchase the Notes. The Investor and/or the Investor’s advisors, who are not affiliated with and not compensated directly or indirectly by the Company or an affiliate thereof, have such knowledge and experience in business and financial matters as will enable them to utilize the information which they have received in connection with the Company and its business to evaluate the merits and risks of an investment, to make an informed investment decision and to protect Investor’s own Notes in connection with the purchase. The Investor has had an opportunity to ask questions of the Company or anyone acting on its behalf and to receive answers concerning the terms of this Agreement and the Notes, as well as about the Company and its business generally, and to obtain any additional information that the Company possesses or can acquire without unreasonable effort or expense, that is necessary to verify the accuracy of the information contained in this Agreement. Further, all such questions have been or will be answered to the full satisfaction of the Investor."
        ),
      ]),
      _c("p", [
        _vm._v(
          "D. The Investor understands that the Notes being purchased are a speculative investment which involves a substantial degree of risk of loss of the Investor’s entire investment in the Notes, and the Investor understands and is fully cognizant of the risk factors related to the purchase of the Notes. The Investor has read, reviewed and understood the risk factors."
        ),
      ]),
      _c("p", [
        _vm._v(
          "E. The Investor understands that any forecasts or predictions as to the Company’s performance are based on estimates, assumptions and forecasts that the Company believes to be reasonable but that may prove to be materially incorrect, and no assurance is given that actual results will correspond with the results contemplated by the various forecasts."
        ),
      ]),
      _c("p", [
        _vm._v(
          "F. The Investor understands that the Notes may not be resold, transferred, assigned or otherwise disposed of unless the proposed disposition is in compliance with the restrictions on transferability under applicable laws and under this Agreement."
        ),
      ]),
      _c("p", [
        _vm._v(
          "G. The Investor understands that there are substantial restrictions on the transferability of the Notes and that there is no public market for the Notes, and none is expected to develop in the near future. Consequently, the Investor understands that it must bear the economic risk of this investment for an indefinite period of time, and that it may not be possible for the Investor to liquidate readily any investment in the Notes, if at all."
        ),
      ]),
      _c("p", [
        _vm._v(
          "H. The Investor confirms that the Investor has been advised to consult with the Investor’s independent attorney regarding legal matters concerning the Company and to consult with independent tax advisers regarding the tax consequences of investing through the Company. The Investor acknowledges and agrees that the Company is providing no warranty or assurance regarding the ultimate availability of any tax benefits to the Investor by reason of the purchase."
        ),
      ]),
      _c("p", [
        _vm._v(
          "I. The Investor acknowledges that Investor is prepared to bear the risk of loss of Investor’s entire investment amount for any Notes Investor purchases."
        ),
      ]),
      _c("h3", [_vm._v("7. Investor Representations Regarding Eligibility")]),
      _c("p", [
        _vm._v(
          "The Investor represents and warrants to the Company the following:"
        ),
      ]),
      _c("p", [
        _vm._v(
          "A. The Investor is eighteen years of age or older, competent to enter into a contractual obligation, and is not a citizen or resident of the United States of America. The principal residence of the Investor is shown on the signature page below."
        ),
      ]),
      _c("p", [
        _vm._v(
          "B. The Investor is able to bear the economic risk of this investment and, without limiting the generality of the foregoing, is able to hold this investment for an indefinite period of time. The Investor has adequate means to provide for the Investor’s current needs and personal contingencies and has a sufficient net worth to sustain the loss of the Investor’s entire investment in Notes."
        ),
      ]),
      _c("p", [
        _vm._v(
          "C. The Investor agrees to provide any additional documentation that the Company may reasonably request or as may be required by the securities administrators or regulators of any state, to confirm that the Investor meets any applicable minimum financial suitability standards and has satisfied any applicable maximum investment limits. Investor has experience making investments similar to the Notes."
        ),
      ]),
      _c("p", [
        _vm._v(
          "D. The Investor represents that no suit, action, claim, investigation or other proceeding is pending or, to the best of the Investor’s knowledge, is threatened against the Investor that questions the validity of the Notes or this Agreement or any action taken or to be taken pursuant to the Notes or this Agreement."
        ),
      ]),
      _c("h3", [
        _vm._v(
          "8. Investor Representations Related to Anti-Money Laundering Measures"
        ),
      ]),
      _c("p", [
        _vm._v(
          "The Company’s intent is to comply with all applicable federal, state and local laws designed to combat money laundering and similar illegal activities. Investor hereby represents, covenants, and agrees that, to the best of Investor’s knowledge based on reasonable investigation:"
        ),
      ]),
      _c("p", [
        _vm._v(
          "A. None of the Investor’s funds tendered for the purchase of Notes (whether payable in cash or otherwise) shall be derived from money laundering or similar activities deemed illegal under applicable laws and regulations."
        ),
      ]),
      _c("p", [
        _vm._v(
          "B. To the extent within the Investor’s control, none of the Investor’s funds tendered for the purchase of Notes will cause the Company or any of its personnel or affiliates to be in violation of applicable anti-money laundering laws and/or any regulations promulgated thereunder."
        ),
      ]),
      _c("p", [
        _vm._v(
          "C. When requested by the Company or the Manager or any designated agent of either or both of them, the Investor will provide any and all additional information, and the Investor understands and agrees that the Company may release confidential information about the Investor and, if applicable, any underlying beneficial owner to regulators and law enforcement authorities, deemed reasonably necessary to ensure compliance with all applicable laws and regulations concerning money laundering and similar activities. The Company reserves the right to request any information as is necessary to verify the identity of the Investor and the source of any funds used to purchase the Notes."
        ),
      ]),
      _c("p", [
        _vm._v(
          "D. The Investor hereby agrees to immediately notify the Company if the Investor knows, or has reason to suspect, that any of the representations in this Section have become incorrect or if there is any change in the information affecting these representations and covenants."
        ),
      ]),
      _c("p", [
        _vm._v(
          "E. The Investor agrees that, if at any time it is discovered that any of the foregoing anti-money laundering representations are incorrect, or if otherwise required by applicable laws or regulations, the Company may undertake appropriate actions, and the Investor agrees to cooperate with such actions, to ensure compliance with such laws or regulations, including, but not limited to segregation and/or redemption of the Investor’s interest in the Notes."
        ),
      ]),
      _c("h3", [_vm._v("9. Representations and Warranties of the Company")]),
      _c("p", [
        _vm._v(
          "The Company hereby represents and warrants to the Investor as follows:"
        ),
      ]),
      _c("p", [
        _vm._v(
          "A. The Company is a Cayman Islands exempted company duly organized, validly existing and in good standing under the laws of the Cayman Islands, having full power and authority to own its properties and carry on its business as conducted."
        ),
      ]),
      _c("p", [
        _vm._v(
          "B. The Company has the requisite power and authority to deliver this Agreement, perform its obligations herein and consummate the transactions contemplated hereby."
        ),
      ]),
      _c("p", [
        _vm._v(
          "D. The Notes to be issued to the Investor pursuant to this Agreement, when issued and delivered in accordance with the terms of this Agreement, will be duly authorized and validly issued."
        ),
      ]),
      _c("h3", [_vm._v("10.Subsequent Sales or Transfers")]),
      _c("p", [
        _vm._v(
          "Subject to the provisions of the Note, the Investor acknowledges and agrees that if any Notes in the Company becomes available for resale or transfer, neither the Company nor any other person shall be obligated to offer the same to the Investor, and such available Notes may be resold or transferred, subject to compliance with any agreements to which such Notes may be subject and any and all applicable state and federal laws, rules and regulations. In addition, the following provisions shall apply to all sales and transfers of the Notes:"
        ),
      ]),
      _c("p", [
        _vm._v(
          "A. No Investor may resell or otherwise transfer any Notes except with the express written consent of the Company."
        ),
      ]),
      _c("p", [
        _vm._v(
          "B. Prior to reselling or transferring any Notes to any person or entity in a manner that otherwise complies with the restrictions noted herein, Investors must offer the Notes (in writing) to the Company (which may, in turn, offer the Notes to other Investors) for purchase (a “Right of First Refusal”)."
        ),
      ]),
      _c("p", [
        _vm._v(
          "C. No sale or transfer shall be effective unless the buyer or transferee has executed and delivered to the Company all documents required by the Company for investing in the Notes and paid the transfer fee to the Company."
        ),
      ]),
      _c("p", [
        _vm._v(
          "D. In the event that all conditions for transfer set forth in this Agreement have been satisfied, then upon due presentment for registration of transfer of a Note at the office or agency of the Company, accompanied by a written instrument of transfer in form satisfactory to the Company duly executed by the Investor or the Investor’s attorney electronically or in writing, a new Note or Notes for an equal aggregate principal amount and like interest rate and maturity will be issued to the transferee in exchange therefor, subject to any transfer fee payable to the Company and to any stamp tax or other governmental charge imposed in connection therewith."
        ),
      ]),
      _c("h3", [_vm._v("11. Electronic Service")]),
      _c("p", [
        _vm._v(
          "The Investor agrees to transact business with the Company and to receive communications relating to the Notes electronically."
        ),
      ]),
      _c("p", [
        _vm._v(
          "A. All notices and communications to be given or otherwise made to the Investor by the Company shall be deemed to be sufficient if sent by electronic mail to such address as set forth for the Investor at the records of the Company."
        ),
      ]),
      _c("p", [
        _vm._v(
          "B. All notices and communications to be given or otherwise made to the Company by Investor shall be deemed to be sufficient."
        ),
      ]),
      _c("p", [
        _vm._v(
          "C. The Investor hereby agrees that the Company may deliver all notices and any and all other documents, information and communications concerning the Company or the Notes by means of e-mail, by posting on an electronic notification to the Investor through the Platform, or by other means of electronic communication."
        ),
      ]),
      _c("p", [
        _vm._v(
          "D. As part of doing business with the Company, the Investor must consent to receiving certain Disclosures (as defined below) electronically, either via the Platform or to the email address provided to the Company by the Investor. By entering into this Agreement, the Investor consents to receive electronically all documents, communications, notices, contracts, and agreements arising from or relating in any way to the Investor’s or the Company’s rights, obligations or services under this Agreement (each, a “Disclosure”). The decision to do business with the Company electronically is the Investor’s. This document informs the Investor of their rights concerning Disclosures."
        ),
      ]),
      _c("p", [
        _vm._v(
          "E. The Investor hereby agrees to keep the Company informed of any change in their email or home mailing address so that the Investor can continue to receive all Disclosures in a timely fashion. If the Investor’s registered e-mail address changes, the Investor must notify the Company by updating their email address through the Platform."
        ),
      ]),
      _c("h3", [_vm._v("12.Indemnity")]),
      _c("p", [
        _vm._v(
          "The Investor hereby indemnifies and holds harmless the Company and its officers, directors, managers, stockholders, partners, members, agents, counsel, servants, employees, affiliates, parent companies, subsidiaries, heirs, personal and legal representatives and administrators, successors and assigns (each an “Indemnified Party”) from, of and against any and all losses, costs, claims, expenses and damages of every kind, known or unknown, contingent or otherwise (including, but not limited to, reasonable attorneys’ fees and court costs incurred), or liability due, which any one of them may incur by reason of (i) failure of the Investor to fulfill any of the terms or conditions of this Agreement, (ii) any breach of any representation or warranty of the Investor, whether contained in this Agreement or elsewhere, or (iii) Investor’s wrongful acts, omissions and representations (and those of the Investor’s employees, agents or representatives). Investor’s obligation to indemnify the Company shall survive termination of this Agreement, regardless of the reason for termination. Any Indemnified Party not being a party to this Agreement may enforce any rights granted to it pursuant to this Section 12 in its own right as if it was a party to this Agreement. The parties to this Agreement may rescind and/or vary the provisions of this Section 12 without the consent of any or all of the Indemnified Parties (other than the Company and the Investor)."
        ),
      ]),
      _c("h3", [_vm._v("13.Confidentiality")]),
      _c("p", [
        _vm._v(
          "The Investor acknowledges that the information contained in the Offering Materials contain confidential and nonpublic information, and agrees that all such information shall be kept in confidence by the Investor and neither used by the Investor for the Investor’s personal benefit (other than in connection with this Subscription) nor disclosed to any third party for any reason; provided, however, that this obligation shall not apply to any such information which:"
        ),
      ]),
      _c("p", [
        _vm._v(
          "A. is part of the public knowledge or literature readily accessible on the date hereof;"
        ),
      ]),
      _c("p", [
        _vm._v(
          "B. becomes part of the public knowledge or literature and readily accessible by publication (except as a result of a breach of this provision);"
        ),
      ]),
      _c("p", [
        _vm._v(
          "C. is received from third parties (except third parties who disclose such information in violation of any confidentiality agreements, including, without limitation, any subscription agreement they may have entered into with the Company); or"
        ),
      ]),
      _c("p", [
        _vm._v(
          "D. is required to be disclosed by applicable law, provided that in such instance the Investor shall give the Company sufficient notice of such disclosure in advance in order that the Company may obtain a protective order preventing disclosure thereof if desired."
        ),
      ]),
      _c("p", [
        _vm._v(
          "The parties hereto hereby agree and acknowledge that a breach of this Section 13 of this Agreement would result in severe and irreparable injury to the other party, which injury could not be adequately compensated by an award of money damages, and the parties therefore agree and acknowledge that they shall be entitled to injunctive relief in the event of any breach of any material term, condition or provision of Section 13 of this Agreement, or to enjoin or prevent such a breach, including without limitation an action for specific performance hereof, and the parties hereby irrevocably consent to the issuance of any such injunction. The parties further agree that no bond or surety shall be required in connection therewith."
        ),
      ]),
      _c("h3", [_vm._v("14.No Advisory Relationship")]),
      _c("p", [
        _vm._v(
          "The Investor hereby acknowledges and agrees that the purchase and sale of any Notes pursuant to this Agreement is an arms-length transaction between the Investor and the Company. In connection with the purchase and sale of the Notes, the Company is not acting as the Investor’s agent or fiduciary. The Company assumes no advisory or fiduciary responsibility in the Investor’s favor in connection with the Notes or the corresponding project investments. The Company has not provided the Investor with any legal, accounting, regulatory or tax advice with respect to the Notes, and the Investor has consulted its own respective legal, accounting, regulatory and tax advisors to the extent that the Investor has deemed appropriate."
        ),
      ]),
      _c("h3", [_vm._v("15.Prohibited Activities")]),
      _c("p", [
        _vm._v(
          "The Investor agrees that the Investor will not do any of the following in connection with any Note, the Underlying Loan or other transactions involving or potentially involving the Company:"
        ),
      ]),
      _c("p", [
        _vm._v(
          "A. take any action to collect, directly or through any third party, any amount under the Note or on the Underlying Loan;"
        ),
      ]),
      _c("p", [
        _vm._v(
          "B. bring a lawsuit or other legal proceeding against any party on the Underlying Loan;"
        ),
      ]),
      _c("p", [
        _vm._v(
          "C. contact the borrower of the Underlying Loan related or unrelated to the Investor’s Note;"
        ),
      ]),
      _c("p", [
        _vm._v(
          "D. contact any third party to which the Underlying Loan has been referred for collection; or"
        ),
      ]),
      _c("p", [
        _vm._v(
          "E. violate any applicable federal, state or local laws, rules or regulations."
        ),
      ]),
      _c("h3", [_vm._v("16.The Company’s Right to Modify Terms")]),
      _c("p", [
        _vm._v(
          "The Company has the right to change any term or provision of this agreement, the form of Note, or the Platform. The Investor authorizes the Company to correct obvious clerical errors appearing in information that the Investor provides to the Company, without notice, although the Company undertakes no obligation to identify or correct such errors."
        ),
      ]),
      _c("h3", [_vm._v("17.Termination")]),
      _c("p", [
        _vm._v(
          "The Company may, in its sole discretion, with or without cause, terminate this Agreement by giving the Investor written notice. In addition, upon the reasonable determination by the Company that the Investor committed fraud or made a material misrepresentation in connection with a commitment to purchase a Note, performed any prohibited activity, or otherwise failed to abide by the terms of this Agreement or other applicable terms and conditions, the Company may, in its sole discretion, immediately and without notice, take one or more of the following actions: (i) terminate or suspend the Investor’s right to purchase Notes; (ii) terminate this Agreement and the Investor’s relationship with the Company, and (iii) repurchase any Notes that have been issued to the Investor. Upon termination of this Agreement, any commitments that the Investor has made to purchase Notes shall be terminated."
        ),
      ]),
      _c("h3", [_vm._v("18.Bankruptcy")]),
      _c("p", [
        _vm._v(
          "In the event that the Investor files or enters bankruptcy, insolvency or other similar proceeding, the Investor agrees to use the best efforts possible to avoid the Company being named as a party or otherwise involved in the bankruptcy proceeding. Furthermore, this Agreement should be interpreted so as to prevent, to the maximum extent permitted by applicable law, any liquidator, provisional liquidator, bankruptcy trustee, receiver or debtor-in-possession from asserting, requiring or seeking that (i) the Investor be allowed by the Company to return the Notes to the Company for a refund or (ii) the Company be mandated or ordered to redeem or withdraw Notes held or owned by the Investor."
        ),
      ]),
      _c("h3", [_vm._v("19.Miscellaneous Provisions")]),
      _c("p", [
        _vm._v(
          "A. This Agreement shall be governed by and construed in accordance with the laws of the Cayman Islands."
        ),
      ]),
      _c("p", [
        _vm._v(
          "B. This Agreement, or the rights, obligations or Notes of the Investor hereunder, may not be assigned, transferred or delegated without the prior written consent of the Company. Any such assignment, transfer or delegation in violation of this Section shall be null and void."
        ),
      ]),
      _c("p", [
        _vm._v(
          "C. The parties agree to execute and deliver such further documents and information as may be reasonably required in order to effectuate the purposes of this Agreement."
        ),
      ]),
      _c("p", [
        _vm._v(
          "D. Investor and the Company agree that the Notes are intended to be indebtedness of the Company. Investor agrees that Investor will not take any position inconsistent with such treatment of the Notes for tax, accounting, or other purposes, unless required by applicable law."
        ),
      ]),
      _c("p", [
        _vm._v(
          "E. Any term of this Agreement may be amended and the observance of any term of this Agreement may be waived (either generally or in a particular instance and either retroactively or prospectively), only with the written consent of each of the parties hereto."
        ),
      ]),
      _c("p", [
        _vm._v(
          "F. If one or more provisions of this Agreement are held to be unenforceable under applicable law, rule or regulation, such provision shall be excluded from this Agreement and the balance of the Agreement shall be interpreted as if such provision were so excluded and shall be enforceable in accordance with its terms."
        ),
      ]),
      _c("p", [
        _vm._v(
          "G. In the event that either party hereto shall commence any suit, action or other proceeding to interpret this Agreement, or determine to enforce any right or obligation created hereby, then such party, if it prevails in such action, shall recover its reasonable costs and expenses incurred in connection therewith, including, but not limited to, reasonable attorney’s fees and expenses and costs of appeal, if any."
        ),
      ]),
      _c("p", [
        _vm._v(
          "H. This Agreement and the documents referred to herein (including without limitation the Note) constitute the entire agreement among the parties and shall constitute the sole documents setting forth terms and conditions of the Investor’s contractual relationship with the Company with regard to the matters set forth herein. This Agreement supersedes any and all prior or contemporaneous communications, whether oral, written or electronic, between the parties. The Investor acknowledges and agrees that this Agreement shall govern all investments on the Platform unless and until superseded by an amended or restated agreement expressly replacing this Agreement."
        ),
      ]),
      _c("p", [
        _vm._v(
          "I. This Agreement may be executed in any number of counterparts, or facsimile counterparts, each of which shall be deemed an original, and all of which together shall constitute one and the same instrument."
        ),
      ]),
      _c("p", [
        _vm._v(
          "J. The titles and subtitles used in this Agreement are used for convenience only and are not to be considered in construing or interpreting this Agreement. The singular number, as used herein, shall be deemed to include the plural number whenever the context so requires."
        ),
      ]),
      _c("p", [
        _vm._v(
          "K. The parties acknowledge that, subject to Section 13, there are no third party beneficiaries of this Agreement, except for any affiliates of the Company that may be involved in the issuance or servicing of Notes on the Platform, which the parties expressly agree shall be third party beneficiaries hereof."
        ),
      ]),
      _c("h3", [_vm._v("20.Limitations on Damages")]),
      _c("p", [
        _vm._v(
          "IN NO EVENT SHALL THE COMPANY BE LIABLE TO THE INVESTOR FOR ANY LOST PROFITS OR SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, EVEN IF INFORMED OF THE POSSIBILITY OF SUCH DAMAGES. THE FOREGOING SHALL BE INTERPRETED AND HAVE EFFECT TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, RULE OR REGULATION."
        ),
      ]),
      _c("h3", [_vm._v("21.Arbitration")]),
      _c("p", [
        _vm._v(
          "A. Either party may, at its sole election, require that the sole and exclusive forum and remedy for resolution of a Claim (as defined below) be final and binding arbitration pursuant to this Section 21 (this “Arbitration Provision”). The arbitration shall be conducted in Hong Kong. As used in this Arbitration Provision, “Claim” shall include any past, present, or future claim, dispute, or controversy involving the Investor (or persons claiming through or connected with the Investor), on the one hand, and the Company (or persons claiming through or connected with the Company), on the other hand, relating to or arising out of this Agreement, any Note, the Platform, and/or the activities or relationships that involve, lead to, or result from any of the foregoing, including (except to the extent provided otherwise in the last sentence of Subsection (F) below) the validity or enforceability of this Arbitration Provision, any part thereof, or the entire Agreement. Claims are subject to arbitration regardless of whether they arise from contract; tort (intentional or otherwise); a constitution, statute, common law, or principles of equity; or otherwise. Claims include (without limitation) matters arising as initial claims, counter-claims, cross-claims, third-party claims, or otherwise. The scope of this Arbitration Provision is to be given the broadest possible interpretation that is enforceable."
        ),
      ]),
      _c("p", [
        _vm._v(
          "B. The party initiating arbitration shall do so with the local arbitration association. The arbitration shall be conducted according to the rules and policies of the administrator selected, except to the extent the rules conflict with this Arbitration Provision or any countervailing law. In the case of a conflict between the rules and policies of the administrator and this Arbitration Provision, this Arbitration Provision shall control, subject to countervailing law, unless all parties to the arbitration consent to have the rules and policies of the administrator apply."
        ),
      ]),
      _c("p", [
        _vm._v(
          "C. If the Company elects arbitration, the Company shall pay all of the administrator’s filing costs and administrative fees incurred by the Company (other than hearing fees). If the Investor elects arbitration, all of the filing costs and administrative fees incurred to both the Investor and to the Company (including hearing fees) shall be paid by the Investor in accordance with the rules of the administrator selected, or in accordance with countervailing law if contrary to the administrator’s rules. The Investor shall bear the expense of its own and the Company’s attorney fees, except as otherwise provided in agreement."
        ),
      ]),
      _c("p", [
        _vm._v(
          "D. Within 30 days of a final award by the arbitrator, a party may appeal the award for reconsideration by a three-arbitrator panel selected according to the rules of the arbitrator administrator. In the event of such an appeal, an opposing party may cross-appeal within 30 days after notice of the appeal. The panel will reconsider de novo all aspects of the initial award that are appealed. Costs and conduct of any appeal shall be governed by this Arbitration Provision and the administrator’s rules, in the same way as the initial arbitration proceeding. Any award by the individual arbitrator that is not subject to appeal, and any panel award on appeal, shall be final and binding."
        ),
      ]),
      _c("p", [
        _vm._v(
          "E. Unless otherwise provided in this Agreement or consented to in writing by all parties to the arbitration, no party to the arbitration may join, consolidate, or otherwise bring claims for or on behalf of two or more individuals or unrelated corporate entities in the same arbitration unless those persons are parties to a single transaction. Unless consented to in writing by all parties to the arbitration, an award in arbitration shall determine the rights and obligations of the named parties only, and only with respect to the claims in arbitration, and shall not (i) determine the rights, obligations, or Notes of anyone other than a named party, or resolve any Claim of anyone other than a named party, or (ii) make an award for the benefit of, or against, anyone other than a named party. No administrator or arbitrator shall have the power or authority to waive, modify, or fail to enforce this Subsection (F), and any attempt to do so, whether by rule, policy, arbitration decision or otherwise, shall be invalid and unenforceable. Any challenge to the validity of this Subsection (F) shall be determined exclusively by a court and not by the administrator or any arbitrator."
        ),
      ]),
      _c("p", [
        _vm._v(
          "F. This Arbitration Provision shall survive (i) suspension, termination, revocation, closure, or amendments to this Agreement and the relationship of the parties; (ii) the bankruptcy or insolvency of any party hereto or other party; and (iii) any transfer of any loan or Note or any amounts owed on such loans or notes, to any other party. If any portion of this Arbitration Provision other than Subsection (F) is deemed invalid or unenforceable, the remaining portions of this Arbitration Provision shall nevertheless remain valid and in force. If arbitration is brought on a class, representative, or collective basis, and the limitations on such proceedings in Subsection (F) are finally adjudicated pursuant to the last sentence of Subsection (F) to be unenforceable, then no arbitration shall be had. In no event shall any invalidation be deemed to authorize an arbitrator to determine Claims or make awards beyond those authorized in this Arbitration Provision."
        ),
      ]),
      _c("h3", [_vm._v("22.Waiver of Court & Jury Rights")]),
      _c("p", [
        _vm._v(
          "THE PARTIES IRREVOCABLY WAIVE ANY AND ALL RIGHTS THEY MAY HAVE TO A TRIAL BY JURY IN ANY ACTION, PROCEEDING OR CLAIM OF ANY NATURE RELATING TO THIS AGREEMENT, THE NOTE, ANY DOCUMENTS EXECUTED IN CONNECTION WITH THE NOTE OR ANY TRANSACTION CONTEMPLATED IN ANY OF SUCH DOCUMENTS. THE PARTIES ACKNOWLEDGE THAT THE FOREGOING WAIVER IS KNOWING AND VOLUNTARY."
        ),
      ]),
      _c("h3", [_vm._v("23.Authority")]),
      _c("p", [
        _vm._v(
          "By executing this Agreement, the Investor expressly acknowledges that the Investor has reviewed the Offering Materials associated with this particular description."
        ),
      ]),
      _c("h1", [_vm._v("SIGNATURE PAGE")]),
      _c("p", [
        _vm._v(
          "IN WITNESS WHEREOF, the undersigned Investor has executed this Subscription Agreement to purchase a Note in the amount shown below the Investor’s signature below on the date set forth below."
        ),
      ]),
      _c("p", [_vm._v("Print Name")]),
      _c("p", { staticClass: "underline" }),
      _c("p", [_vm._v("Residential Address")]),
      _c("p", { staticClass: "underline" }),
      _c("p", [_vm._v("Mailing Address (if different)")]),
      _c("p", { staticClass: "underline" }),
      _c("p", [_vm._v("Email Address")]),
      _c("p", { staticClass: "underline" }),
      _c("p", [_vm._v("Signature")]),
      _c("p", { staticClass: "underline" }),
      _c("p", [_vm._v("Date")]),
      _c("p", { staticClass: "underline" }),
      _c("p", [_vm._v("Amount of Notes subscribed for")]),
      _c("p", { staticClass: "underline" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }